import React from 'react';
import logo from './logo.svg';
import './App.css';

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {releases: {}};
    this.downloadRelease = this.downloadRelease.bind(this);
  }
  componentDidMount(){
    fetch('/releases.json?t=' + Date.now()).then(response => response.json()).then(data => this.setState({releases: data.releases}));
  }
  downloadRelease(type){
    if(this.state.releases.length == 0){
      return;
    }
    if(typeof this.state.releases[type] == "object"){
      Object.assign(document.createElement('a'), { target: '_blank', href: this.state.releases[type].url}).click();
    }
  }
  render(){
    return (
      <div className="App">
        <header className="App-header">
          <a className="logo"><img className="logo-image" src="/images/icon.png"/><span className="logo-text">iCloudMetaMap</span></a>
          <br/>
          <div className="header-cover">
            <div className="header-info">
              <h1 className="header-statement">Time flies but memories last forever.</h1>
              <span className="header-substatement">iCloudMetaMap analyzes your iCloud in order to create a navigatable feature-packed map with your memories.</span>
              <div className="btn-pair">
                <button onClick={() => this.downloadRelease("windows-installer")} className="download-btn">{this.state.releases["windows-installer"] != null ? "Download for Windows (Installer)"  : "Fetching releases..."}</button>
                <div className="dropdown">
                  <button className="dropdown-btn">&#9660;</button>
                  <div className="dropdown-downloads">
                    <a onClick={() => this.downloadRelease("windows-portable")}>Windows (Portable)</a>
                    <a target="_blank" href="https://github.com/Ruegg/iCloud-Meta-Map">Source Code</a>
                  </div>
                </div>
              </div>
            </div>
          <img className="header-snippet window-snippet" src="/images/JapanScreenSm.png" />
          </div>
          <div className="tech-section">
            <h1 className="tech-statement">Built on modern frameworks</h1>
            <div className="tech-stack">
              <img className="tech-credit" src="/images/technology/electron.svg"/>
              <img className="tech-credit" src="/images/technology/React.png"/>
              <img className="tech-credit" src="/images/technology/Redux.png"/>
              <img className="tech-credit" src="/images/technology/Mapbox.png"/>
            </div>
          </div>
          <div className="feature-section">
            <div className="feature">
              <img className="feature-snippet" src="/images/JapanPicsSm.png" />
              <div className="feature-info">
                <h1 className="feature-statement">Location based gallery</h1>
                <span className="feature-substatement">Curious about the traffic on the map? Clicking on the map will bring up a gallery of photos/videos taken within a nearby radius.</span>
              </div>
            </div>
            <div className="feature">
              <div className="feature-info">
                <h1 className="feature-statement">Yearly Sorted Data</h1>
                <span className="feature-substatement">Organizing data by year helps to visualize trends over time, from changing up your daily coffee shop to moving cities.</span>
              </div>
              <img className="feature-snippet" src="/images/MapScreenSm.png" />
            </div>
          </div>
		  <div className="feature-section">
			<h1 className="privacy-statement">Privacy</h1>
			<span className="privacy-substatement">Not only does iCloudMetaMap not store any data, it doesn't even receive any. Aside from the website, iCloudMetaMap doesn't need any servers for its functionality. All communications are directly from your computer to iCloud, and that's never going to change.</span>
		  </div>
          <div className="footer-section">
            <span className="footer-text">iCloud Meta Map is in no way affiliated with Apple, and was written by Andre Ruegg by reverse engineering the iCloud API</span>
          </div>
        </header>
      </div>
    );
  }
};

export default App;
